* {
  box-sizing: border-box;
}

.canvas-container {
  z-index: 10;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  cursor: url("https://github.com/chenglou/react-motion/raw/master/demos/demo8-draggable-list/cursor.png")
      39 39,
    auto;
}

@tailwind base;
@tailwind components;
@tailwind utilities;